import React from 'react';
import { Routes,Route, Navigate } from 'react-router-dom';
import Login from './../Components/Login';
import Error from './../Route/Error';

function PublicRouter() {
  return (
    <React.Fragment>
        <Routes>
          <Route path="/admin" exact element={<Login/>} />
          <Route path="/admin/login" exact element={<Login/>} />
          <Route path="/admin/blog"  element={<Navigate to="/admin" replace  />}/>
          <Route path="/admin/blog-category" element={<Navigate to="/admin" replace  />}/>
          <Route path="/admin/add-blog" element={<Navigate to="/admin" replace  />}/>
          <Route path="/admin/edit-blog/:id" element={<Navigate to="/admin" replace  />}/>
          <Route path="/*" exact element={<Error/>} />
        </Routes>
    </React.Fragment>
  )
}

export default PublicRouter;