export const ActionTypes = {
    ABOUT: 'ABOUT',
    LOGIN:'LOGIN',
    PAGES:'PAGES',
    BLOG_ADD:'BLOG_ADD',
    EDIT_BLOG:'EDIT_BLOG',
    BLOG_CATEGORY:'BLOG_CATEGORY',
    LAYOUT_OPTIONS:'LAYOUT_OPTIONS',
    SHOW_RIGHT_SIDEBAR:'SHOW_RIGHT_SIDEBAR',
    LAYOUT_TYPE:'LAYOUT_TYPE',
    LAYOUT_MODE_TYPE:'LAYOUT_MODE_TYPE',
    LAYOUT_WIDTH:'LAYOUT_WIDTH',
    LEFT_SIDEBAR_THEME:'LEFT_SIDEBAR_THEME',
    LEFT_SIDEBAR_THEME_IMAGE:'LEFT_SIDEBAR_THEME_IMAGE',
    LEFT_SIDEBAR_TYPE:'LEFT_SIDEBAR_TYPE',
    TOPBAR_THEME:'TOPBAR_THEME',
    IS_PRELOADER:'IS_PRELOADER',
    IS_MOBILE:'IS_MOBILE',
    SHOW_SIDEBAR:'SHOW_SIDEBAR',
    LEFT_MENU:'LEFT_MENU',
    USER_LOGOUT: 'USER_LOGOUT'
}