import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Toast,
} from "reactstrap";
import { Link } from "react-router-dom";
import Foloosi from "./../../assets/images/foloosilogo.png";
import profile from "./../../assets/images/profile-img.png";
import { useDispatch, useSelector } from "react-redux";
import { postApiCall, reloadWindowToPath, setCookie } from "gfdu";
import GBL from "../../Constants/GlobalVariables";
import { ActionTypes } from "../../Constants/ActionTypes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Login() {
  const [show, setShow] = useState(false);
  const [hidden, setHidden] = useState(true);
  const { login } = useSelector((state) => state);
  const dispatch = useDispatch();

  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  useEffect(() => {
    dispatch(updateState(ActionTypes.LOGIN, { username: "", password: "" }));
  }, {});

  const handleLogin = () => {
    if (!login.username) {
      toast.error("Enter valid Username");
    } else if (!login.password) {
      toast.error("Enter valid Password");
    } else {
      const data = {
        email: login.username,
        password: login.password,
      };
      postApiCall(`v1/user/login`, data, (response) => {
        if (response.success) {
          // setCookie("login_token", response.data.authentication);
          setCookie("auth_token", response.data.auth_token);
          setCookie("user_id", response.data.user_id);
          toast.success(response.message);
          setTimeout(() => {
            window.location.href="/admin/blog";
          }, 1000);
        } else {
          toast.error(response.message);
        }
      });
    }
  };

  const handleChange = (e) => {
    dispatch(
      updateState(ActionTypes.LOGIN, { [e.target.name]: e.target.value })
    );
  };
  const handleKeypress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  return (
    <React.Fragment>
      <div id="ippopay_loading">
        <div className="ippopay_wrapper">
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col className="col-7">
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Welcome Back !</h5>
                            <p>Sign in to continue</p>
                          </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div className="auth-logo">
                        <Link to="/" className="auth-logo-light">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={Foloosi}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                        <Link to="/" className="auth-logo-dark">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={Foloosi}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        <div className="form-horizontal">
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="username">
                              Username
                            </Label>
                            <Input
                              name="username"
                              value={login.username}
                              id="username"
                              className="form-control"
                              placeholder="Enter username"
                              type="text"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="mb-3">
                            <Label className="form-label" htmlFor="password">
                              Password
                            </Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={login.password}
                                id="password"
                                placeholder="Enter Password"
                                type={hidden ? "password" : "text"}
                                onChange={handleChange}
                                onKeyPress={handleKeypress}
                              />
                              <button
                                onClick={() => setHidden(!hidden)}
                                className="btn btn-light "
                                type="button"
                                tabIndex="1"
                                id="password-addon"
                              >
                                <i className="fas fa-eye-slash"></i>
                              </button>
                            </div>
                          </div>
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              onClick={handleLogin}
                            >
                              Log In
                            </button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>&copy; {new Date().getFullYear()} Foloosi </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Login;
