import React, { useEffect, useState } from "react";
import { getApiCall, postApiCall } from "gfdu";
import axios from "axios";
import { Link } from "react-router-dom";
import Headers from "../blogsite/Header";
import Footers from "../blogsite/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import scrollToTop from "./utils";
import Lottie from "react-lottie-player";
import Loading from "./loader.json"

const Home = () => {
  document.title = "blog-site";
  const [list, setList] = useState([]);
  const [data, setData] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [blog_name, setBlogName] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    scrollToTop();
    AOS.init({
      disable: function () {
        var maxWidth = 1024;
        return window.innerWidth < maxWidth;
        // return true;
      },
    });
  },[])
  useEffect(() => {
    getData();
  }, []);
  useEffect(()=>{
      getData();
  },[blog_name])
  useEffect(() => {
      getData();
  }, [searchFilter]);

  const getData = () => {
    let queryParams = "";
    queryParams += searchFilter === "" ? "" : "search=" + searchFilter;
    queryParams += queryParams === "" ? "" : "&";
      queryParams += blog_name === "" ? "" : "category_name=" + blog_name;
      getApiCall(`v1/blog/blog-list?${queryParams}`,
      (response) => {
      setList(response?.data?.blogList);
      setData(response?.data?.categories);
    });
  };
const handleonChange = (e) =>{
  setBlogName(e);
  console.log(e);
  setLoading(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);

}
const handleInputSearch = (e) =>{
  setSearchFilter(e.target.value);
}
  return (
    <>
    {loading  ? 
      // <div className="shapes"></div>
      <div id="ippopay_loading">
                <Lottie
                    animationData={Loading}
                    play
                    loop={false}
                    style={{ width: "160px", height: "160px" }}
                />
            </div>
      :
    <>
     <Headers />
      <div className="containers">
        <div className="row head-p-t">
          <div className="col-xs-12 col-sm-12 col-md-12 left_side_wrap">
            <div className="row trans_form">
              {list?.length > 0 && 
              <>
              <div className="col-xs-12 col-sm-7 col-md-7 text-center">
                  <Link to={`/details/${list[0]?.permalink}`}
                  >
                        <img
                          className="banner_img"
                          src={list[0]?.blog_banner}
                          alt="Banner"
                        />
                      </Link>
              </div>
              <div className="col-xs-12 col-sm-7 col-md-5 text-small">
                <div className="blog_big_title">
                {list[0].blog_name}
                </div>
                <div className="blog_main_desc">
                {list[0].blog_description}
                </div>
              </div>
              </>}
            </div>
          </div>
          
        </div>
        <div className="row category_sticky">
          <div className="col-xs-12 col-sm-12 col-md-8">
            <ul className="view_more_list">
              <li>
                <a href="/">All</a>
              </li>
              {data?.length > 0 && 
                data?.map((category, i) => (
                  <li key={i}>
                    <Link to="#" onClick={() =>handleonChange(category.category_name)}>{category.category_name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4">
            <form className="search">    
              <input className="search_blog" placeholder="Search"
              defaultValue={searchFilter}
              onChange={(e)=>handleInputSearch(e)}/>
             <button  className="search_icon"><i className="fa fa-search"></i></button>
             </form>
          </div>
        </div>

        <div className="row p-top">
          <div className="col-xs-12 col-sm-12 col-md-12 right_side_wrap">
            <div className="row">
              {list?.length > 0 &&
                list?.slice(1).map((list, j) => (
                  <div className="col-xs-12 col-sm-7 col-md-4" key={j}>
                    <div className="blog_list_head" data-aos="zoom-in" data-aos-duration="1000">
                      <Link to={`/details/${list?.permalink}`}>
                        <img
                          className="icon_img" 
                          src={list?.blog_banner}
                          alt="Banner"
                        />
                      </Link>
                      <div className="mini_title ">{list.blog_name}</div>
                      <div className="mini_desc">
                        {list.blog_description}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footers/>
      </>
                }
    </>
  );
};

export default Home;
