import React from "react";
import ippoerror from "./../assets/images/error-img.png";
const Error = () => {
    return (
        <div id="ippopay_loading">
            <div className="ippopay_loading_img errorPage">
                <img src={ippoerror} />
            </div>
        </div>
    );
};
export default Error;