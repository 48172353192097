import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams,useNavigate} from "react-router-dom";
import { getApiCall, postApiCall } from "gfdu";
import Headers from "../blogsite/Header";
import Footers from "../blogsite/Footer";
import { unescape } from 'lodash'; 
const View = () => {
  document.title = "Blog-view";
  const { id } = useParams();
  const [list, setList] = useState({});
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getApiCall(`v1/blog/${id}`,
    (response) => { 
      setList(response?.data?.blog);
      setData(response?.data?.blog?.tags);
    });
  };
  const unescapedContent = unescape(list?.blog_content);
  const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
  }
  return (
    <>
     <Headers />
      <div className="containers">
        <div className="row head-p-t">
          <div className="col-xs-12 col-sm-12 col-md-10 p-0">
            <div className="blog_view_title"> {list?.blog_name}</div>
            <div className="view_blogs_desc">{list?.blog_description}</div>
            <div className="col-xs-12 text-center m-b-20 p-0">
              <img className="view_blogs_img" src={list?.blog_banner} alt="" />
            </div>
            <div className="view_blogs_desc mt-4">
              <span
                dangerouslySetInnerHTML={{ __html: unescapedContent}}
              ></span>
            </div>
            <div className="row category_sticky">
          <div className="col-xs-12 col-sm-12 col-md-8">
            <ul className="view_more_list">
              {data?.length > 0 &&
                data?.map((tags, i) => (
                  <li key={i}>
                    <Link to="#" >{tags.tag_name}</Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
          </div>
          <div className="col-xs-12 col-md-2">
            <div className="blog_icon_sticky">
            <div class="back-button">
            <button class="label bounce" onClick={goBack}>
              <span class="arrow-icon">&larr;</span> Back
              </button>
              </div>
              <ul className="social-buttons">
                <li>
                  <a
                    target="_blank"
                    className="social-button social-button--linkedin"
                    href="https://www.linkedin.com/sharing/share-offsite/?url=http://3.109.238.48:3800/blog-details/why-should-schools-in-india-have-a-digital-payment-infrastructure"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    className="social-button social-button--twitter"
                    href="https://twitter.com/share?url=http://3.109.238.48:3800/blog-details/why-should-schools-in-india-have-a-digital-payment-infrastructure"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    className="social-button social-button--facebook"
                    href="https://www.facebook.com/sharer/sharer.php?u=http://3.109.238.48:3800/blog-details/why-should-schools-in-india-have-a-digital-payment-infrastructure"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footers/>
    </>
  );
};

export default View;
