import { ActionTypes } from "../../Constants/ActionTypes";

const blogstate = {
  blogList: [],
};

const addBlogState = {
  blog_list: [],
  title: "",
  add_blog_category_id:"",
  add_blog_category_name:"",
  add_blog_permalink:"",
  add_blog_description: "",
  add_blog_category: "",
  tags:[],
  blog_image:"",
  add_blog_content:"",
};

const editBlogState = {
  edit_list: {},
  title: "",
  edit_blog_category_id:"",
  edit_blog_category_name:"",
  edit_blog_permalink:"",
  edit_blog_description: "",
  edit_blog_category: "",
  edit_tag_list:[],
  edit_image:"",
  edit_blog_content:"",
};



const categorystate = {
  categoryList: [],
  category_name: "",
  category_seo_name:"",
  edit_category: "",
  edit_category_seo_name:"",
  id: "",


};



export function bloglist(state = blogstate, action) {
  switch (action.type) {
    case ActionTypes.PAGES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export function categorylist(state = categorystate, action) {
  switch (action.type) {
    case ActionTypes.BLOG_CATEGORY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function addblog(state = addBlogState, action) {
  switch (action.type) {
    case ActionTypes.BLOG_ADD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function editblog(state = editBlogState, action) {
  switch (action.type) {
    case ActionTypes.EDIT_BLOG:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
