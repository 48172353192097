import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes,Route, Navigate } from 'react-router-dom';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import AddBlog from './../Components/Blog/AddBlog';
import Blog from './../Components/Blog/Blog';
import EditBlog from './../Components/Blog/EditBlog';
import BlogCategory from './../Components/BlogCategory/BlogCategory';
import Error from './../Route/Error';
var leftSideBarType = "default";
const   PrivateRouter = () => {
  const dispatch = useDispatch();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const updateState = (actionType, value) => dispatch => {
      dispatch({type : actionType, payload : value});
      return Promise.resolve();
  };
  const {layout} = useSelector((state)=>state);
  useEffect(() => {
    changeBodyAttribute("data-sidebar", layout.leftSideBarTheme);
    changeBodyAttribute("data-topbar", layout.topbarTheme);
    changeBodyAttribute("data-sidebar-image", layout.leftSideBarThemeImage);
    changeBodyAttribute("data-layout-mode", layout.layoutModeType);
    if (layout.layoutWidth === "boxed") {
      changeBodyAttribute("data-layout-size", layout.layoutWidth)
      changeBodyAttribute("data-layout-scrollable", layout.scrollable)
    } else if (layout.layoutWidth === "scrollable") {
      changeBodyAttribute("data-layout-scrollable", !layout.scrollable)
    } else {
      changeBodyAttribute("data-layout-size", layout.layoutWidth)
      changeBodyAttribute("data-layout-scrollable", layout.scrollable)
    }
    switch (layout.leftSideBarType) {
      case "compact":
          changeBodyAttribute("data-sidebar-size", "small")
          manageBodyClass("sidebar-enable", "remove")
          manageBodyClass("vertical-collpsed", "remove")
          break
      case "icon":
          changeBodyAttribute("data-sidebar-size", "")
          changeBodyAttribute("data-keep-enlarged", "true")
          manageBodyClass("vertical-collpsed", "add")
          break
      case "condensed":
          manageBodyClass("sidebar-enable", "add")
          if (window.screen.width >= 992) {
          manageBodyClass("vertical-collpsed", "remove")
          manageBodyClass("sidebar-enable", "remove")
          manageBodyClass("vertical-collpsed", "add")
          manageBodyClass("sidebar-enable", "add")
          } else {
          manageBodyClass("sidebar-enable", "add")
          manageBodyClass("vertical-collpsed", "add")
          }
          break
      default:
          changeBodyAttribute("data-sidebar-size", "")
          manageBodyClass("sidebar-enable", "remove")
          if (!isMobile)
          manageBodyClass("vertical-collpsed", "remove")
          break
    }
  },[]);
  const changeBodyAttribute = (attribute, value) => {
    if (document.body) document.body.setAttribute(attribute, value);
    return true
  }
  const manageBodyClass = (cssClass, action = "toggle") => {
    switch (action) {
      case "add":
        if (document.body) document.body.classList.add(cssClass);
        break
      case "remove":
        if (document.body) document.body.classList.remove(cssClass);
        break
      default:
        if (document.body) document.body.classList.toggle(cssClass);
        break
    }
    return true
  }
  const toggleMenuCallback = () => {
      if (leftSideBarType === "default") {
          dispatch(updateState("leftSidebar",{"condensed":isMobile}));
      } else if (leftSideBarType === "condensed") {
          dispatch(updateState("leftSidebar",{"default":isMobile}));
      }
  };
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
        theme={layout.leftSideBarThemeTypes}
        type={layout.leftSideBarType}
        isMobile={isMobile}
        />
        <div className="main-content">
          <Routes>
            <Route path='/admin/blog' exact element={<Blog/>} />
            <Route path="/admin/blog-category" exact element={<BlogCategory/>}/>
            <Route path="/admin/add-blog" exact element={<AddBlog/>}/>
            <Route path="/admin/edit-blog/:id" element={<EditBlog/>}/>
            <Route path="/admin" element={<Navigate to="/admin/blog" replace  />}/>
            <Route path="/*" exact element={<Error/>} />
          </Routes>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PrivateRouter;