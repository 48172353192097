import React from "react";
import SidebarContent from "./SidebarContent";
import { Link } from "react-router-dom";

import logo from "../assets/images/logo.png";
import Foloosi from "../assets/images/foloosilogo.png";
const Sidebar = () => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/admin/blog" className="logo logo-light">
            <span className="logo-sm">
              <img src={Foloosi} alt="" height="22" className="img-filter-icon" />
            </span>
            <span className="logo-lg">
              <img src={logo}  alt="" height="19" className="img-filter"/>
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
            <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};
export default Sidebar;
