import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {ActionTypes} from "./Constants/ActionTypes";
import Routers from './Route/Routers';
import BlogRoutes from "./Route/BlogRoutes";
import cookie from "react-cookies";
import {initializeApiCall, putApiCall,storageRemoveAll} from "gfdu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


initializeApiCall(
  {
    "x-consumer-custom-id":cookie.load("user_id"),
    "Content-Type": "application/json",
    "auth_token":cookie.load("auth_token"),
    // "Authorization":`Bearer ${cookie.load("login_token")}`,
    "platform":"web"       
  },
    "https://blogapi.foloosi.com/",
    // "http://192.168.0.165:3001/",
  false,
  [ 
    { status_code: "401", func: () => (cookie.remove("auth_token"),cookie.remove("user_id"),window.location.href="/admin")},
    { status_code: "405", func:()=>{}}
]
) 
const App = () => {
  const [checkPath, setCheckPath] = useState(false);
  let location = useLocation();
    useEffect(() => {
      if(location.pathname.includes("admin")){
        setCheckPath(true);
      }else{
        setCheckPath(false);
      }
    },[location,checkPath])
    return (
        <React.Fragment>
          <ToastContainer/>
          {checkPath ?
            <Routers/>   
            :
            <BlogRoutes/>
          }
        </React.Fragment>
    );
};

export default App;