import React, {useEffect, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, FormGroup } from "reactstrap";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import {ActionTypes} from "./../Constants/ActionTypes";
import bgimg1 from "./../assets/images/sidebar/img1.jpg";
import bgimg2 from "./../assets/images/sidebar/img2.jpg";
import bgimg3 from "./../assets/images/sidebar/img3.jpg";
import bgimg4 from "./../assets/images/sidebar/img4.jpg";
import {layoutTypes, layoutModeTypes, layoutWidthTypes, topBarThemeTypes, leftBarThemeImageTypes, leftSidebarTypes, leftSideBarThemeTypes} from "./../Constants/Layouts";

const RightSidebar = () => {
    const {layout, showRight} = useSelector((state)=>state);
    const dispatch = useDispatch();
    const updateState = (actionType,value)=>()=>{
        dispatch({type:actionType,payload:value});
        return Promise.resolve();
    }
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    useEffect(() => {
        changeBodyAttribute("data-layout", layout.layoutType);
    }, [layout.layoutType]);

    useEffect(() => {
        changeBodyAttribute("data-layout-mode", layout.layoutModeType);
    },[layout.layoutModeType]);

    useEffect(() => {
        if (layout.layoutWidth === "boxed") {
            changeBodyAttribute("data-layout-size", layout.layoutWidth)
            changeBodyAttribute("data-layout-scrollable", layout.scrollable)
        } else if (layout.layoutWidth === "scrollable") {
            changeBodyAttribute("data-layout-scrollable", !layout.scrollable)
        } else {
            changeBodyAttribute("data-layout-size", layout.layoutWidth)
            changeBodyAttribute("data-layout-scrollable", layout.scrollable)
        }
    },[layout.layoutWidth]);

    useEffect(() => {
        changeBodyAttribute("data-topbar", layout.topbarTheme)
    },[layout.topbarTheme]);

    useEffect(() => {
        switch (layout.leftSideBarType) {
            case "compact":
                changeBodyAttribute("data-sidebar-size", "small")
                manageBodyClass("sidebar-enable", "remove")
                manageBodyClass("vertical-collpsed", "remove")
                break
            case "icon":
                changeBodyAttribute("data-sidebar-size", "")
                changeBodyAttribute("data-keep-enlarged", "true")
                manageBodyClass("vertical-collpsed", "add")
                break
            case "condensed":
                manageBodyClass("sidebar-enable", "add")
                if (window.screen.width >= 992) {
                manageBodyClass("vertical-collpsed", "remove")
                manageBodyClass("sidebar-enable", "remove")
                manageBodyClass("vertical-collpsed", "add")
                manageBodyClass("sidebar-enable", "add")
                } else {
                manageBodyClass("sidebar-enable", "add")
                manageBodyClass("vertical-collpsed", "add")
                }
                break
            default:
                changeBodyAttribute("data-sidebar-size", "")
                manageBodyClass("sidebar-enable", "remove")
                if (!isMobile)
                manageBodyClass("vertical-collpsed", "remove")
                break
        }
    },[layout.leftSideBarType]);

    useEffect(() => {
        changeBodyAttribute("data-sidebar", layout.leftSideBarTheme);
    },[layout.leftSideBarTheme]);
     
    useEffect(() => {
        changeBodyAttribute("data-sidebar-image", layout.leftSideBarThemeImage)
    },[layout.leftSideBarThemeImage]);

    const showRightSidebarAction = () => {
        dispatch(updateState(ActionTypes.SHOW_RIGHT_SIDEBAR,{showRightSidebar:!showRight.showRightSidebar}))
    }
    const layoutTypeFn = async(e) => {
        if (e.target.checked) {
            dispatch(updateState(ActionTypes.LAYOUT_OPTIONS,{layoutType:e.target.value}));
        }
    }
    const layoutModeTypeFn = (e) => {
        if (e.target.checked) {
            dispatch(updateState(ActionTypes.LAYOUT_OPTIONS,{layoutModeType:e.target.value}));
        }
    }
    const layoutWidthFn = (e) => {
        if (e.target.checked) {
            dispatch(updateState(ActionTypes.LAYOUT_OPTIONS,{layoutWidth:e.target.value}));
        }
    }
    const topbarThemeFn = (e) => {
        if (e.target.checked) { 
            dispatch(updateState(ActionTypes.LAYOUT_OPTIONS,{topbarTheme:e.target.value}));
        }
    }
    const leftSideBarTypeFn = (e) => {
        if (e.target.checked) {
            dispatch(updateState(ActionTypes.LAYOUT_OPTIONS,{leftSideBarType:e.target.value}));
        }
    }
    const leftSideBarThemeFn = (e) => {
        if (e.target.checked) {
            dispatch(updateState(ActionTypes.LAYOUT_OPTIONS,{leftSideBarTheme:e.target.value}));
        }
    }
    const leftSideBarThemeImageFn = (e) => {
        if (e.target.checked) {
            dispatch(updateState(ActionTypes.LAYOUT_OPTIONS,{leftSideBarThemeImage:e.target.value}));
        }
    }
    const changeBodyAttribute = (attribute, value) => {
        if (document.body) document.body.setAttribute(attribute, value);
        return true
    }
    const manageBodyClass = (cssClass, action = "toggle") => {
        switch (action) {
          case "add":
            if (document.body) document.body.classList.add(cssClass);
            break
          case "remove":
            if (document.body) document.body.classList.remove(cssClass);
            break
          default:
            if (document.body) document.body.classList.toggle(cssClass);
            break
        }
        return true
    }
    return (
    <React.Fragment>
     <div className="right-bar" id="right-bar">
      <SimpleBar style={{ height: "900px" }}>
        <div data-simplebar className="h-100">
          <div className="rightbar-title px-3 py-4">
            <Link
              to="#"
              onClick={e => {
                  e.preventDefault()
                  showRightSidebarAction()
                }}
              className="right-bar-toggle float-end"
            >
              <i className=" fas fa-times" />
            </Link>
            <h5 className="m-0">Settings</h5>
          </div>

          <hr className="my-0" />

          <div className="p-4">
            <div className="radio-toolbar">
              <span className="mb-2 d-block">Layouts Mode</span>
              <input
                type="radio"
                id="radioLight"
                name="radioLight"
                value={layoutModeTypes.LIGHT}
                checked={layout.layoutModeType === layoutModeTypes.LIGHT}
                onChange={layoutModeTypeFn}
              />
              <label className="me-1" htmlFor="radioLight">Light</label>
              <input
                type="radio"
                id="radioDark"
                name="radioDark"
                value={layoutModeTypes.DARK}
                checked={layout.layoutModeType === layoutModeTypes.DARK}
                onChange={layoutModeTypeFn}
              />
              <label htmlFor="radioDark">Dark</label>
            </div>

            <hr className="mt-1" />

            <div className="radio-toolbar">
              <span className="mb-2 d-block" id="radio-title">
                Layout Width
              </span>
              <input
                type="radio"
                id="radioFluid"
                name="radioWidth"
                value={layoutWidthTypes.FLUID}
                checked={layout.layoutWidth === layoutWidthTypes.FLUID}
                onChange={layoutWidthFn}
              />
              <label className="me-1" htmlFor="radioFluid">Fluid</label>
              <input
                type="radio"
                id="radioBoxed"
                name="radioWidth"
                value={layoutWidthTypes.BOXED}
                checked={layout.layoutWidth === layoutWidthTypes.BOXED}
                onChange={layoutWidthFn}
              />
              <label htmlFor="radioBoxed" className="me-1">
                Boxed
              </label>
              <input
                type="radio"
                id="radioscrollable"
                name="radioscrollable"
                value={layoutWidthTypes.SCROLLABLE}
                checked={layout.layoutWidth === layoutWidthTypes.SCROLLABLE}
                onChange={layoutWidthFn}
              />
              <label htmlFor="radioscrollable">Scrollable</label>
            </div>
            <hr className="mt-1" />

            <div className="radio-toolbar">
              <span className="mb-2 d-block" id="radio-title">
                Topbar Theme
              </span>
              <input
                type="radio"
                id="radioThemeLight"
                name="radioTheme"
                value={topBarThemeTypes.LIGHT}
                checked={layout.topbarTheme === topBarThemeTypes.LIGHT}
                onChange={topbarThemeFn}
              />
              <label className="me-1" htmlFor="radioThemeLight">Light</label>
              <input
                type="radio"
                id="radioThemeDark"
                name="radioTheme"
                value={topBarThemeTypes.DARK}
                checked={layout.topbarTheme === topBarThemeTypes.DARK}
                onChange={topbarThemeFn}
              />
              <label className="me-1" htmlFor="radioThemeDark">Dark</label>
              {layout.layoutType === "vertical" ? null : (
                <>
                  <input
                    type="radio"
                    id="radioThemeColored"
                    name="radioTheme"
                    value={topBarThemeTypes.COLORED}
                    checked={layout.topbarTheme === topBarThemeTypes.COLORED}
                    onChange={topbarThemeFn}
                  />
                  <label className="me-1" htmlFor="radioThemeColored">Colored</label>{" "}
                </>
              )}
            </div>
            <hr className="mt-1" />
            <div className="radio-toolbar">
              <span className="mb-2 d-block" id="radio-title">
                Left Sidebar Type{" "}
              </span>
              <input
                type="radio"
                id="sidebarDefault"
                name="sidebarType"
                value={leftSidebarTypes.DEFAULT}
                checked={layout.leftSideBarType === leftSidebarTypes.DEFAULT}
                onChange={leftSideBarTypeFn}
              />
              <label className="me-1" htmlFor="sidebarDefault">Default</label>
              <input
                type="radio"
                id="sidebarCompact"
                name="sidebarType"
                value={leftSidebarTypes.COMPACT}
                checked={layout.leftSideBarType === leftSidebarTypes.COMPACT}
                onChange={leftSideBarTypeFn}
              />
              <label className="me-1" htmlFor="sidebarCompact">Compact</label>
              <input
                type="radio"
                id="sidebarIcon"
                name="sidebarType"
                value={leftSidebarTypes.ICON}
                checked={layout.leftSideBarType === leftSidebarTypes.ICON}
                onChange={leftSideBarTypeFn}
              />
              <label className="me-1" htmlFor="sidebarIcon">Icon</label>
            </div>

            <hr className="mt-1" />

            <div className="radio-toolbar coloropt-radio">
              <span className="mb-2 d-block" id="radio-title">
                Left Sidebar Color Options
              </span>
              <Row>
                <Col>
                  <input
                    type="radio"
                    id="leftsidebarThemelight"
                    name="leftsidebarTheme"
                    value={leftSideBarThemeTypes.LIGHT}
                    checked={layout.leftSideBarTheme === leftSideBarThemeTypes.LIGHT}
                    onChange={leftSideBarThemeFn}
                  />
                  <label
                    htmlFor="leftsidebarThemelight"
                    className={layout.layoutModeType === "dark" ? "bg-dark rounded-circle wh-30 me-1" : "bg-light rounded-circle wh-30 me-1"}
                  ></label>

                  <input
                    type="radio"
                    id="leftsidebarThemedark"
                    name="leftsidebarTheme"
                    value={leftSideBarThemeTypes.DARK}
                    checked={layout.leftSideBarTheme === leftSideBarThemeTypes.DARK}
                    onChange={leftSideBarThemeFn}
                  />
                  <label
                    htmlFor="leftsidebarThemedark"
                    className={layout.layoutModeType === "light" ? "bg-dark rounded-circle wh-30 me-1" : "bg-light rounded-circle wh-30 me-1"}
                  ></label>

                  <input
                    type="radio"
                    id="leftsidebarThemecolored"
                    name="leftsidebarTheme"
                    value={leftSideBarThemeTypes.COLORED}
                    checked={layout.leftSideBarTheme === leftSideBarThemeTypes.COLORED}
                    onChange={leftSideBarThemeFn}
                  />
                  <label
                    htmlFor="leftsidebarThemecolored"
                    className="bg-colored rounded-circle wh-30 me-1"
                  ></label>
                </Col>
              </Row>
              <Row>
                <Col>

                  <input
                    type="radio"
                    id="leftsidebarThemewinter"
                    name="leftsidebarTheme"
                    value={leftSideBarThemeTypes.WINTER}
                    checked={layout.leftSideBarTheme === leftSideBarThemeTypes.WINTER}
                    onChange={leftSideBarThemeFn}
                  />
                  <label
                    htmlFor="leftsidebarThemewinter"
                    className="gradient-winter rounded-circle wh-30 me-1"
                  ></label>

                  <input
                    type="radio"
                    id="leftsidebarThemeladylip"
                    name="leftsidebarTheme"
                    value={leftSideBarThemeTypes.LADYLIP}
                    checked={layout.leftSideBarTheme === leftSideBarThemeTypes.LADYLIP}
                    onChange={leftSideBarThemeFn}
                  />
                  <label
                    htmlFor="leftsidebarThemeladylip"
                    className="gradient-lady-lip rounded-circle wh-30 me-1"
                  ></label>

                  <input
                    type="radio"
                    id="leftsidebarThemeplumplate"
                    name="leftsidebarTheme"
                    value={leftSideBarThemeTypes.PLUMPLATE}
                    checked={layout.leftSideBarTheme === leftSideBarThemeTypes.PLUMPLATE}
                    onChange={leftSideBarThemeFn}
                  />
                  <label
                    htmlFor="leftsidebarThemeplumplate"
                    className="gradient-plum-plate rounded-circle wh-30 me-1"
                  ></label>

                  <input
                    type="radio"
                    id="leftsidebarThemestrongbliss"
                    name="leftsidebarTheme"
                    value={leftSideBarThemeTypes.STRONGBLISS}
                    checked={layout.leftSideBarTheme === leftSideBarThemeTypes.STRONGBLISS}
                    onChange={leftSideBarThemeFn}
                  />
                  <label
                    htmlFor="leftsidebarThemestrongbliss"
                    className="gradient-strong-bliss rounded-circle wh-30 me-1"
                  ></label>
                  <input
                    type="radio"
                    id="leftsidebarThemesgreatwhale"
                    name="leftsidebarTheme"
                    value={leftSideBarThemeTypes.GREATWHALE}
                    checked={layout.leftSideBarTheme === leftSideBarThemeTypes.GREATWHALE}
                    onChange={leftSideBarThemeFn}
                  />
                  <label
                    htmlFor="leftsidebarThemesgreatwhale"
                    className="gradient-strong-great-whale rounded-circle wh-30 me-1"
                  ></label>
                </Col>
              </Row>
            </div>
            <div className="radio-toolbar imgopt-radio">
              <span className="mb-2 d-block" id="radio-bgimg">
                Left Sidebar Bg Image
              </span>
              <div className="d-flex gap-2 flex-wrap">
                <input
                  type="radio"
                  id="leftsidebarThemebgimg1"
                  name="leftsidebarThemeImage"
                  value={leftBarThemeImageTypes.IMG1}
                  checked={layout.leftSideBarThemeImage === leftBarThemeImageTypes.IMG1}
                  onChange={leftSideBarThemeImageFn}
                />

                <label htmlFor="leftsidebarThemebgimg1">
                  <img
                    alt="sidebar bg image"
                    width="90"
                    className="themesideimage rounded"
                    src={bgimg1}
                  />
                </label>
                {"   "}

                <input
                  type="radio"
                  id="leftsidebarThemebgimg2"
                  name="leftsidebarThemeImage"
                  value={leftBarThemeImageTypes.IMG2}
                  checked={layout.leftSideBarThemeImage === leftBarThemeImageTypes.IMG2}
                  onChange={leftSideBarThemeImageFn}
                />

                <label htmlFor="leftsidebarThemebgimg2">
                  <img
                    alt="sidebar bg image"
                    width="90"
                    className="themesideimage rounded"
                    src={bgimg2}
                  />
                </label>
                {"   "}

                <input
                  type="radio"
                  id="leftsidebarThemebgimg3"
                  name="leftsidebarThemeImage"
                  value={leftBarThemeImageTypes.IMG3}
                  checked={layout.leftSideBarThemeImage === leftBarThemeImageTypes.IMG3}
                  onChange={leftSideBarThemeImageFn}
                />

                <label htmlFor="leftsidebarThemebgimg3">
                  <img
                    alt="sidebar bg image"
                    width="90"
                    className="themesideimage rounded"
                    src={bgimg3}
                  />
                </label>
                {"   "}
                <input
                  type="radio"
                  id="leftsidebarThemebgimg4"
                  name="leftsidebarThemeImage"
                  value={leftBarThemeImageTypes.IMG4}
                  checked={layout.leftSideBarThemeImage === leftBarThemeImageTypes.IMG4}
                  onChange={leftSideBarThemeImageFn}
                />
                <label htmlFor="leftsidebarThemebgimg4">
                  <img
                    alt="sidebar bg image"
                    width="90"
                    className="themesideimage rounded"
                    src={bgimg4}
                  />
                </label>
                {"   "}

                <input
                  type="radio"
                  id="leftsidebarThemenone"
                  name="leftsidebarThemeImage"
                  value={leftBarThemeImageTypes.NONE}
                  checked={layout.leftSideBarThemeImage === leftBarThemeImageTypes.NONE}
                  onChange={leftSideBarThemeImageFn}
                />
                <label htmlFor="leftsidebarThemenone">
                  <div style={{ width: "40px", height: "80px" }}>
                    <div className="bg-light border px-2 h-100 shadow-none">
                      <div className="verticalcontent">None</div>
                    </div>
                  </div>
                </label>
                {"   "}
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
      </div>
      <div className="rightbar-overlay"></div>
    </React.Fragment>
  );
};
export default RightSidebar;