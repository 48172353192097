import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  BreadcrumbItem,
  Button,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteApiCall,
  getApiCall,
  patchApiCall,
  textCapitalize,
  putApiCall,
} from "gfdu";
import { ActionTypes } from "../../Constants/ActionTypes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../pagination";

const Blog = () => {
  //meta title
  document.title = "Blog";
  const { bloglist } = useSelector((state) => state);
  const [searchFilter, setSearchFilter] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [recordsLength, setRecordsLength] = useState(0);
  const [count, setCount] = useState("");
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise;
  };
  // const startIndex = (pageNo - 1) * limit + 1;
  const endIndex = Math.min(pageNo * limit, count);
  useEffect(() => {
      blogList(pageNo);
  }, [pageNo,limit,searchFilter]);
  const options = [ 
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100'},
  ];
  const blogList = (page) => {
    setPageNo(page);
    let queryParams = "";
    queryParams += searchFilter === "" ? "" : "&search=" + searchFilter;
    getApiCall(`v1/blog/list?page=${page}&limit=${limit}${queryParams}`, (response) => {
      if (response.success) {
        setRecordsLength(response?.data?.totalPages);
        setCount(response?.data?.totalData);
        dispatch(
          updateState(ActionTypes.PAGES, {blogList: response.data.blogList,
          })
        );
        
      }else {
        toast.error(response.message);
      }
    });
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Blog",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmDelete) => {
        if (confirmDelete) {
          deleteApiCall(`v1/blog/delete-blog/${id}`, {}, (response) => {
            if (response.success) {
              if (pageNo) {
                if (count.length > 0) {
                  blogList(count.slice(0, (count - 1) * limit));
                } else {
                  blogList(1);
                }
            }
          }
          });
          swal(`Poof! Blog has been deleted!`, {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Your Blog is safe!");
        }
      })
      .catch((error) => {
        toast.error("Please Check The URL");
      });
  };
  const changeStatus = (id) => {
    const data = {
      status: id == 1 ? 0 : id == 0 ? 1 : id,
    };
    putApiCall(`v1/blog/change-status/${id}`, data, (response) => {
      if (response.success) {
        toast.success(response.message);
        blogList(pageNo);
      }
    });
  };
  
const handleInputSearch = (e) =>{
  e.preventDefault();
  setSearchFilter(e.target.value);
  setPageNo(1);
  blogList(1);
}
const optionChange = (selectedOption)=>{
  setLimit(selectedOption.value);
}

  return (
    <React.Fragment>
      
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Blog</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      Blog
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      List
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="mb-4">
                    <Row>
                      <Col xs="12" md="2">
                        <Label
                          htmlFor=" blog-category"
                          className="col-form-label"
                        >
                          Show Entries
                        </Label>
                        <div className="col-6">
                          <Select
                            className="select2-selection website-dropdown"
                            options={options}
                            onChange={(e)=>optionChange(e)}
                           value={{ value: limit, label: limit }}
                          />
                        </div>
                      </Col>
                      <Col xs="12" md="2">
                        <Label
                          htmlFor=" blog-category"
                          className="col-form-label"
                        >
                          Search
                        </Label>
                        <Input 
                        type="text"
                        className="search_input"
                        placeholder="Search"
                        value={searchFilter}
                        onChange={(e)=>handleInputSearch(e)}
                        />
                      </Col>

                      <Col xs="12" md="8" className="mt-3">
                        <Link to="/admin/add-blog">
                          <Button
                            color="secondary"
                            className="float-end btn btn-secondary waves-effect waves-light"
                          >
                            Add
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </div>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>S.No</Th>
                            <Th>Blog Name</Th>
                            <Th>Category Name</Th>
                            <Th>Status</Th>
                            <Th>Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {bloglist?.blogList?.map((list,index) => (
                            <>
                              <Tr key={index}>
                             <Td>{pageNo * limit - limit + index + 1}</Td>
                                <Td>{textCapitalize(list?.blog_name)}</Td>
                                
                                <Td>{textCapitalize(list?.category_name)}</Td>
                                <Td>
                                  <a>
                                    <Badge
                                      onClick={() =>
                                        changeStatus(list?.blog_no)
                                      }
                                      pill
                                      className={
                                        list?.status == 1
                                          ? "badge-soft-success font-size-12"
                                          : "badge-soft-warning font-size-12"
                                      }
                                    >
                                      {list?.status == 0
                                        ? "Inactive"
                                        : "Active"}
                                    </Badge>
                                  </a>
                                </Td>
                                <Td>
                                  <Link to={`/admin/edit-blog/${list?.blog_no}`}>
                                    <i className="bx bx-edit bx-xs"></i>
                                  </Link>
                                  <span
                                    onClick={() => deleteData(list?.blog_no)}
                                  >
                                    <i className="bx bx-trash bx-xs delete-icon pointer"></i>
                                  </span>
                                </Td>
                              </Tr>
                            </>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="table-bottom-content">
                    <div>
                        Showing 1 to {endIndex} of{" "}
                        {count} entries
                      </div>
                      <div>
                      <Pagination handle={blogList} list={recordsLength} searchFilter={searchFilter}></Pagination>
                    </div>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Blog;
