import { ActionTypes } from "../../Constants/ActionTypes";

const loginstate = {
    username:"",
    password:""
}
export function login(state = loginstate,action){
        switch(action.type){
            case ActionTypes.LOGIN:
                return {
                    ...state,
                    ...action.payload
                }
                default:
            return state;
        }
}