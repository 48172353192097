import React, { useEffect, useState } from "react";


const Footers = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleScrollButtonVisibility = () => {
    const scrolled = window.scrollY;
    if (scrolled > 500) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleScrollButtonVisibility);
    return () => {
      window.removeEventListener("scroll", toggleScrollButtonVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
        <div className={`go_top ${isActive ? 'actives' : ''}`} onClick={scrollToTop}><i className="fas fa-angle-up"></i></div>
      <footer>&copy; Foloosi {new Date().getFullYear()} All Rights Reserved.</footer>
    </>
  );
};
export default Footers;
