import React from "react";
// import ippoloader from "./../assets/images/ip-loader.svg";
const Loader = () => {
    return (
        <div id="ippopay_loading">
            <div className="ippopay_loading_img">
                {/* <img src={ippoloader} /> */}
            </div>
        </div>
    );
};
export default Loader;