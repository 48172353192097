
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  BreadcrumbItem,
} from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";
import ReactQuill, { Quill } from "react-quill";
// import ImageUploader from "quill-image-uploader"; 
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApiCall, patchApiCall, postApiCall, putApiCall } from "gfdu";
import { ActionTypes } from "../../Constants/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Route/Loader";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import axios from "axios";
// var Font = Quill.import("formats/font");
// Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];
// Quill.register(Font, true);

const EditBlog = () => {
  document.title = "Edit Blog";

  const { id } = useParams();
  const navigate = useNavigate();
  const { editblog } = useSelector((state) => state);
  let data = editblog.edit_blog_content === "";
  const [imageLoad, setImageLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [bannerLink, setBannerLink] = useState("");
  const [showEditBlog, setShowEditBlog] = useState(false);
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise;
  };

  useEffect(() => {
    blogList(id);
  }, []);

  useEffect(() => {
    setCategoryList(
      editblog.edit_blog_categorylist?.map((detail, i) => {
        return { value: detail._id, label: detail.category_name };
      })
    );
  }, [editblog.edit_blog_categorylist]);
  const blogList = (id) => {
    getApiCall(`V1/blog/blog-detail/${id}`,(response) => {
      if (response.success) {
        dispatch(
          updateState(ActionTypes.EDIT_BLOG, {
            edit_list: response?.data?.blog,
            title:response?.data?.blog?.blog_name,
            edit_blog_category_id:response?.data?.blog?.category_id,
            edit_blog_category_name: response?.data?.blog?.category_name,
            edit_blog_permalink:response?.data?.blog?.permalink,
            edit_tag_list: response?.data?.blog?.tags.split(","),
            edit_blog_description: response?.data?.blog?.blog_description,
            edit_blog_content: response?.data?.blog?.blog_content,
            edit_image:response?.data?.blog?.blog_banner,
          }) 
        );
        setShowEditBlog(true);
       
      } else {
        toast.error(response.message);
      }
    });
  };
  const postEditData = () => {
    if (!editblog?.title) {
      toast.info("Title should not be empty");
    } else if (!editblog?.edit_blog_category_name) {
      toast.info("Category should not be empty");
    }  else if (!editblog?.edit_blog_permalink) {
      toast.info("permalink should not be empty");
    }else if (editblog?.edit_tag_list.length < 1) {
      toast.info("Tags should not be empty");
    } else if (!editblog?.edit_blog_description) {
      toast.info("Blog Description should not be empty");
    }  else if (
      editblog?.edit_blog_content.replace(/<(.|\n)*?>/g, "").trim().length === 0
    ) {
      toast.info("Blog Content should not be empty");
    } else if (!editblog?.edit_image) {
      toast.info("Image should not be empty");
    }else {
      const data = {
        blog_name: editblog?.title,
        category_name:editblog?.edit_blog_category_name, 
        category_id: editblog?.edit_blog_category_id,
        permalink: editblog?.edit_blog_permalink,
        blog_description:editblog?.edit_blog_description,
        blog_banner:bannerLink == "" ? editblog?.edit_image : bannerLink, 
        blog_content:editblog?.edit_blog_content,
        tag_name: editblog?.edit_tag_list?.toString(),
      };
      putApiCall(`V1/blog/update-blog/${id}`, data, 
      (response) => {
        if (response.success) {
          navigate("/admin/blog");
           toast.success(response.message);
        } else {
          toast.error(response?.response?.data?.message);
        }
      });
    }
  };
  
  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    getApiCall(
      `V1/category/category-list`,
      (response) => {
        if (response.success) {
          dispatch(
            updateState(ActionTypes.EDIT_BLOG, {
              edit_blog_categorylist: response?.data,
            })
          );
        } 
      }
    );
  };
  const handleTagChange = (e) => {
    dispatch(updateState(ActionTypes.EDIT_BLOG, { edit_tag_list: e }));
  };

  const handleChange = (e) => {
    dispatch(
      updateState(ActionTypes.EDIT_BLOG, { [e.target.name]: e.target.value })
    );
  };
  const handleContentChange = (content, delta, source, editor) => {
    dispatch(
      updateState(ActionTypes.EDIT_BLOG, { edit_blog_content: content })
    );
  };
  const handleChangeCategory = (e) => {
    dispatch(
      updateState(ActionTypes.EDIT_BLOG, {
        edit_blog_category_id: e.value,
        edit_blog_category_name: e.label,
      })
    );
  };
  const uploadImage = async (event) => {
    const file = event.target.files[0];
    setLoading(false);
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/svg"
    ) {
      if (file.size > 5e6) {
        toast.info("Please upload a file smaller than 5 MB");
      } else {
        let data = new FormData();
        data.append("file", event.target.files[0]);
        data.append("upload_preset", "ifslewdl");
        data.append("api_key", "724539947133222");
        data.append("api_secret", "ByPeu1fryhajcdv_F7keQw8pyUs");
        axios
          .post("https://api.cloudinary.com/v1_1/foloosi/image/upload", data)
          .then((response) => {
          if (response) {
            setBannerLink(response?.data?.secure_url);
            setLoading(true);
           setImageLoad(true)
          }
        });
      }
    } else {
      toast.info(
        "File does not support. You must use .png, svg, .jpg and .jpeg "
      );
    }
  };
  const quillRefEdit = useRef();
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
    "size",
    // "font"
  ];
  const modules_edit = useMemo(
    () => ({
      toolbar: {
        container: [
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          { 'header': [1, 2, 3, 4, 5, 6, false] },
          // { font: Font.whitelist },
            { 'size': ['small', false, 'large', 'huge'] },
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          "link",
          "image",
          "clean",
          "video" ,
          { align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' },
        ],
        handlers: {
          image: function (value) {
            const editor = quillRefEdit.current.getEditor();
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();

            input.onchange = async () => {
              const file = input.files[0];
              setLoading(false);
              let data = new FormData();
              data.append("file", file);
              data.append("upload_preset", "ifslewdl");
              data.append("api_key", "724539947133222");
              data.append("api_secret", "ByPeu1fryhajcdv_F7keQw8pyUs");
              axios
                .post("https://api.cloudinary.com/v1_1/foloosi/image/upload", data)
                .then((response) => {
                if (response) {
                  toast.success(response.message);
                  const url = response?.data?.secure_url;
                  editor.insertEmbed(editor.getSelection(), "image", url);
                  setLoading(true);
                } else {
                  toast.error("Upload failed");
                }
              });
            };
          },
          video: function (value) {
            const editor = quillRefEdit.current.getEditor();
            if (value) {
              var url = prompt("Enter the URL");
              url = getVideoUrl(url);
              if (url) {
                editor.insertEmbed(editor.getSelection(), "video", url);
              }
            }
          },
        },
      },
    }),
    []
  );
  const getVideoUrl = (url) => {
    let match =
      url?.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
      ) ||
      url?.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
      ) ||
      url?.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
    if (match && match[2]?.length === 11) {
      return "https" + "://www.youtube.com/embed/" + match[2] + "?showinfo=0";
    }
    if ((match = url?.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
      return (
        (match[1] || "https") + "://player.vimeo.com/video/" + match[2] + "/"
      );
    }
    return null;
  };
  const deleteData = () => {
    setBannerLink("");
    setImageLoad(false);
  };
  return (
    <React.Fragment>
      {!loading && <Loader />}
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Edit Blog</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/admin/blog">Blog</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Edit Blog</BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {showEditBlog ? ( <div>
                      <Row className="mb-4">
                        <Label
                          htmlFor="blog-title"
                          className="col-sm-3 col-form-label"
                        >
                           Blog Title * 
                        </Label>
                        <Col sm={4}>
                          
                          <Input
                            type="text"
                            className="form-control"
                            id="blog-title"
                            placeholder="Enter Blog Title"
                            defaultValue={editblog?.title}
                            onChange={handleChange}
                            name="title"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4 antd-drop">
                        <Label
                          htmlFor="blog-category"
                          className="col-sm-3 col-form-label"
                        >
                          Blog Category *
                        </Label>
                        <Col sm={4}>
                          <Select
                            defaultValue={{
                              value: editblog?.edit_blog_category_id ,
                              label: editblog?.edit_blog_category_name ,
                            }}
                            id="blog-category"
                            className="select2-selection"
                            options={categoryList}
                            onChange={handleChangeCategory}
                            name="setCategoryList"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="blog-permalink"
                          className="col-sm-3 col-form-label"
                        >
                          Permalink *
                        </Label>
                        <Col sm={4}>
                          <Input
                            type="text"
                            className="form-control"
                            id="blog-permalink"
                            placeholder="Enter Blog Permalink"
                            defaultValue={editblog?.edit_blog_permalink}
                            onChange={handleChange}
                            name="edit_blog_permalink"
                          />
                        </Col>
                      </Row>
                      
                      <Row className="mb-4">
                        <Label
                          htmlFor="tag-name"
                          className="col-sm-3 col-form-label"
                        >
                          Tag Name *
                        </Label>
                        <Col sm={7}>
                          <TagsInput
                            name="edit_tag_list"
                            value={editblog?.edit_tag_list}
                            onChange={handleTagChange}
                          />

                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="blog-description"
                          className="col-sm-3 col-form-label"
                        >
                          Blog Description *
                        </Label>
                        <Col sm={7}>
                          <Input
                            type="textarea"
                            className="form-control"
                            id="blog-description"
                            placeholder=""
                            defaultValue={editblog?.edit_blog_description}
                            onChange={handleChange}
                            name="edit_blog_description"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="blog-content"
                          className="col-sm-3 col-form-label"
                        >
                          Blog Content *
                        </Label>
                        <Col sm={7}>
                          <ReactQuill
                            ref={quillRefEdit}
                            theme="snow"
                            modules={modules_edit}
                            formats={formats}
                            value={editblog?.edit_blog_content}
                            onChange={handleContentChange}
                            name="edit_blog_content"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label className="col-sm-3 col-form-label">
                        Blog Banner *
                        </Label>
                        <Col sm={7}>
                          {!imageLoad ? (
                            <label
                              htmlFor="imageUpload"
                              className="dropzone col-12 pointer"
                            >
                              <input
                                type="file"
                                className="hide"
                                id="imageUpload"
                                onChange={uploadImage}
                              />
                              <div className="dz-message needsclick mt-2">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                </div>
                                <h4>Click to upload.</h4>
                              </div>
                            </label>
                          ) : (
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    src={bannerLink}
                                  />
                                </Col>
                                <Col>
                                  <span onClick={deleteData}>
                                    <i className="bx bx-trash bx-xs delete-icon pointer "></i>
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          )}
                          {!bannerLink &&
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    src={editblog?.edit_image}
                                  />
                                </Col>
                              </Row>
                            </div>}
                        </Col>
                      </Row>
                      <Row className="justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              color="primary"
                              className="w-md"
                              onClick={postEditData}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>) : null}
                   
                  </CardBody>
                </Card>
              </Col>
            </Row>
          
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditBlog;
