import React, { useEffect } from "react";
import Logo from "../staticview/images/logo_new.png";

const Header = () => {
  return (
    <>
      <header>
        <div className="containers">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3">
              <a href="https://foloosi.com" className="blog-logo">
                <img src={Logo} className="img-filter-icons" />
                <span>Blog</span>
              </a>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9">
              <ul className="view_more_right">
                <li>
                  <a href="https://www.foloosi.com/" target="_blank">Products</a>
                </li>
                <li>
                  <a href="https://foloosi.com/pricing" target="_blank">Pricings</a>
                </li>
                <li>
                  <a href="https://docs.foloosi.com/" target="_blank">Developers</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
