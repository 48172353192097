import { getCookie } from "gfdu";
import React, { useEffect, useState } from "react";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import "../assets/scss/theme.scss";
import "../assets/style.css";
import cookie from "react-cookies";
import {initializeApiCall, putApiCall,storageRemoveAll} from "gfdu";


function Routers() {
  useEffect(() => {      
    let timer = null;
    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        logoutUser();
      }, 24 * 60 * 1000);
    };
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keypress", resetTimer);

    return () => {
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keypress", resetTimer);
    };
  }, []);
  const logoutUser = () => {
    putApiCall("v1/user/logout",{},(response) => {
        if(response.success){
          cookie.remove("auth_token");
          cookie.remove("user_id");
          cookie.remove("login_token");
          localStorage.removeItem('persist:theme-root');
          storageRemoveAll('persist:theme-root');
          window.location.href='/admin'  
          }
    });
  };
  return (getCookie("auth_token") || getCookie("login_token")) ? 
  <PrivateRouter /> 
  : 
  <PublicRouter />;
}

export default Routers;