import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  BreadcrumbItem,
  Label,
  Form,
  Input,
  Button,
  Modal,
  Toast,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteApiCall,
  getApiCall,
  patchApiCall,
  postApiCall,
  textCapitalize,
  putApiCall
} from "gfdu";
import { ActionTypes } from "../../Constants/ActionTypes";
import { toast, ToastContainer } from "react-toastify";
import GlobalVariables from "../../Constants/GlobalVariables";
import Select from "react-select";
import Pagination from "../pagination";
const BlogCategory = () => {
  //meta title
  document.title = "Blog Category";

  const [modal_center, setmodal_center] = useState(false);
  const [editmodal, setEditModal] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [recordsLength, setRecordsLength] = useState(0);
  const [count, setCount] = useState("");
  const [limit, setLimit] = useState(10);
  const { categorylist } = useSelector((state) => state);
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  // const startIndex = (pageNo - 1) * limit + 1;
  const endIndex = Math.min(pageNo * limit, count);
  useEffect(() => {
        getCategoryList(pageNo);
    }, [limit,searchFilter,pageNo]);
  useEffect(() => {
    dispatch(
      updateState(ActionTypes.BLOG_CATEGORY, {
        category_name: "",
        category_seo_name:"",
        categoryList: [],
      })
    );
  }, []);
  const options = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100'},
  ];
  const getCategoryList = (page) => {
    setPageNo(page);
    let queryParams = "";
    queryParams += searchFilter === "" ? "" : "&search=" + searchFilter;
    getApiCall(`v1/category/get-all-category?page=${page}&limit=${limit}${queryParams}`,(response) => {
        if (response.success) {
          setRecordsLength(response?.data?.totalPages);
          setCount(response?.data?.totalData);
          dispatch(
            updateState(ActionTypes.BLOG_CATEGORY, {
              categoryList: response?.data?.category_list,
            })
          );
        } else {
          toast.error(response.message);
        }
      }
    );
  };
  const handleChange = (e) => {
    dispatch(
      updateState(ActionTypes.BLOG_CATEGORY, {
        [e.target.name]: e.target.value,
      })
    );
  };
  const tog_center = () => {
    setmodal_center(!modal_center);
    dispatch(
      updateState(ActionTypes.BLOG_CATEGORY, {category_name:"",category_seo_name:""})
    );
  };
  const tog_editcenter = (id) => {
    dispatch(
      updateState(ActionTypes.BLOG_CATEGORY, {
        edit_category:id.category_name,
        edit_category_seo_name:id.category_seo_name,
        id: id.category_no,
        
      })
    );
    setEditModal(!editmodal);
  };

  const postCategoryData = () => {
     if (!categorylist.category_name) {
      toast.info("Category Name Should not be empty");
    } else if (!categorylist.category_seo_name) {
      toast.info("Category Seo Name Should not be empty");
    }else {
      const data = {
        category_name:categorylist?.category_name,
        category_seo_name:categorylist?.category_seo_name,
      };
      postApiCall(`v1/category/create-blog-category`, data, (response) => {
        if (response.success) {
          toast.success(response.message);
          setmodal_center(!modal_center);
          getCategoryList(pageNo);
        } else{
          toast.error(response?.response?.data?.message)
        }
      });
    }
  };

  const updateCategoryData = () => {
    if (!categorylist.edit_category) {
      toast.info("Category Name Should not be empty");
    } else if (!categorylist.edit_category_seo_name) {
      toast.info("Category Seo Name Should not be empty");
    } else {
      const data = {
        category_name:categorylist?.edit_category,
        category_seo_name:categorylist?.edit_category_seo_name,
        category_no: categorylist?.id
      };
      putApiCall(
        `v1/category/update-blog-category`,data,(response) => {
          if (response.success) {
            toast.success(response.message);
            setEditModal(!editmodal);
            getCategoryList(pageNo);
          } else {
            toast.error(response?.response?.data?.message);
          }
        }
      );
    }
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Category",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmDelete) => {
        if (confirmDelete) {
          deleteApiCall(
            `v1/category/delete-blog-category/${id}`,
            {},
            (response) => {
              if (response.success) {
                if (pageNo) {
                  if (count.length > 0) {
                    getCategoryList(count.slice(0, (count - 1) * limit));
                  } else {
                    getCategoryList(1);
                  }
              }
              }
            }
          );
          swal(`Poof! Blog Category has been deleted`, {
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          swal("Your Blog Category is safe!", { icon: "success" });
        }
      })
      .catch((error) => {
        toast.error("Please Check The URL");
      });
  };

  const changeStatus = (id) => {
    const data = {
      status: id == 1 ? 0 : id == 0 ? 1 : id,
    };
    putApiCall(
      `v1/category/status-change-category/${id}`,
      data,
      (response) => {
        if (response.success) {
          toast.success(response.message);
          getCategoryList(pageNo);
        }
      }
    );
  };
  const handleInputSearch = (e) =>{
    e.preventDefault();
    setSearchFilter(e.target.value);
    setPageNo(1);
    getCategoryList(1);
  }
  const optionChange = (selectedOption)=>{
    setLimit(selectedOption.value);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Blog Category</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>Category</BreadcrumbItem>
                    <BreadcrumbItem active>List</BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  
                  {/* <div className="table-top-content"> */}
                  <div className="mb-4">
                    <Row>

                    <Col xs="12" md="2">
                        <Label
                          htmlFor="blog-website"
                          className="col-form-label"
                        >
                          Show entries
                        </Label>
                        <div className="col-6">
                        <Select
                            className="select2-selection website-dropdown"
                            options={options}
                            onChange={(e)=>optionChange(e)}
                           value={{ value: limit, label: limit }}
                          />
                        </div>
                      
                      </Col>
                      
                    <Col xs="12" md="2">
                        <Label
                          htmlFor="blog-website"
                          className="col-form-label"
                        >
                          Search
                        </Label>
                      
                        <Input 
                        type="text"
                        className="search_input"
                        placeholder="Search"
                        value={searchFilter}
                        onChange={(e)=>handleInputSearch(e)}
                        />
                      </Col>
                      
                      <Col xs="12" md="8" className="mt-3">
                        <span
                          className=" float-end btn mb-2 me-2 btn btn-primary"
                          onClick={() => {
                            tog_center();
                          }}
                        >
                          <i className="bx bx-add-to-queue"></i>Create Category
                        </span>
                      </Col>
                    </Row>
                  </div>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>S.No</Th>
                            <Th data-priority="1">Name</Th>
                            <Th data-priority="3">Status</Th>
                            <Th data-priority="1">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {categorylist?.categoryList?.map((list, i) => (
                            <Tr key={i}>
                          <Td>{pageNo * limit - limit + i + 1}</Td>
                              <Td>{textCapitalize(list?.category_name)}</Td>
                              <Td>
                                <a>
                                  <Badge
                                    onClick={() => changeStatus(list?.category_no)}
                                    pill
                                    className={
                                      list?.status == 1
                                        ? "badge-soft-success font-size-12"
                                        : "badge-soft-warning font-size-12"
                                    }
                                  >
                                    {textCapitalize  (list?.status == 1
                                        ? "Active"
                                        : "Inactive")}
                                  </Badge>
                                </a>
                              </Td>
                              <Td>
                                <i
                                  className="bx bx-edit bx-xs pointer"
                                  onClick={() => tog_editcenter(list)}
                                ></i>

                                <i
                                  className="bx bx-trash bx-xs delete-icon pointer"
                                  onClick={() => deleteData(list?.category_no)}
                                ></i>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="table-bottom-content">
                  <div>
                        Showing 1 to {endIndex} of{" "}
                        {count} entries
                      </div>
                      <div>
                      <Pagination handle={getCategoryList} list={recordsLength} searchFilter={searchFilter}></Pagination>
                    </div>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <div>
        <Modal
          isOpen={modal_center}
          toggle={() => {
            tog_center();
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Add Blog Category</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row className="mb-4">
              <Label
                sm={4}
                htmlFor="blog-category"
                className="col-sm-3 col-form-label"
              >
                Blog Category Name
              </Label>
              <Col sm={7}>
                <Input
                  type="text"
                  className="form-control"
                  id="blog-category"
                  placeholder="Enter Your"
                  name="category_name"
                  value={categorylist.category_name}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Label
                sm={4}
                htmlFor="blog-category"
                className="col-sm-3 col-form-label"
              >
                Blog Category Seo Name
              </Label>
              <Col sm={7}>
                <Input
                  type="text"
                  className="form-control"
                  id="blog-category"
                  placeholder="Enter Your"
                  name="category_seo_name"
                  value={categorylist.category_seo_name}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <div className="add-form">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    outline
                    className="submit-category"
                    onClick={postCategoryData}
                  >
                    Submit
                  </Button>
                  <Button
                    color="danger"
                    outline
                    onClick={() => {
                      setmodal_center(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={editmodal}
          toggle={() => {
            tog_editcenter();
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Edit Blog Category</h5>
            <button
              type="button"
              onClick={() => {
                setEditModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row className="mb-4">
              <Label
                sm={4}
                htmlFor="blog-category"
                className="col-sm-3 col-form-label"
              >
                Blog Category Name
              </Label>
              <Col sm={7}>
                <Input
                  type="text"
                  className="form-control"
                  id="blog-category"
                  placeholder="Enter Your"
                  name="edit_category"
                  value={categorylist.edit_category}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Label
                sm={4}
                htmlFor="blog-category"
                className="col-sm-3 col-form-label"
              >
                Blog Category Seo Name
              </Label>
              <Col sm={7}>
                <Input
                  type="text"
                  className="form-control"
                  id="blog-category"
                  placeholder="Enter Your"
                  name="edit_category_seo_name"
                  value={categorylist.edit_category_seo_name}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <div className="add-form">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    outline
                    className="submit-category"
                    onClick={updateCategoryData}
                  >
                    Submit
                  </Button>
                  <Button
                    color="danger"
                    outline
                    onClick={() => {
                      setEditModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default BlogCategory;
