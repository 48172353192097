import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {ActionTypes} from "../Constants/ActionTypes";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
import RightSidebar from "./RightSidebar";
import { putApiCall, removeCookie, storageRemoveAll,setCookie, reloadWindowToPath ,storageRemoveItem} from "gfdu";

const Header = () => {
  const {showRight} = useSelector((state)=>state);
  const dispatch = useDispatch();
  const updateState = (actionType,value)=>()=>{
      dispatch({type:actionType,payload:value});
      return Promise.resolve();
  }
  const [menu, setMenu] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const tToggle = () => {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  useEffect(() => {
    
  },[showRight.showRightSidebar])
  const showRightSidebarAction = () => {
    manageBodyClass("right-bar-enabled", "add");
    dispatch(updateState(ActionTypes.SHOW_RIGHT_SIDEBAR,{showRightSidebar:!showRight.showRightSidebar}))
  }
  const manageBodyClass = (cssClass, action = "toggle") => {
      switch (action) {
        case "add":
          if (document.body) document.body.classList.add(cssClass);
          break
        case "remove":
          if (document.body) document.body.classList.remove(cssClass);
          break
        default:
          if (document.body) document.body.classList.toggle(cssClass);
          break
      }
      return true
  }

  const deleteAllCookies = () => {
    let cookies = document.cookie.split(";");
    const d = new Date();
    d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;" + expires + ";path=/";
    }
}
  const logout=()=>{
    putApiCall(`v1/user/logout`,{},(response)=>{
        if(response.success){
          dispatch(
            updateState(ActionTypes.USER_LOGOUT, {})
          );
          deleteAllCookies();
          storageRemoveAll();
          window.location.href="/admin";
        }
    })
  }

  return (
    <React.Fragment>
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <button
            type="button"
            onClick={() => {
              tToggle();
            }}
            className="btn btn-sm px-3 font-size-16 header-item "
            id="vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
        </div>
        <div className="d-flex">
          <span className="d-none d-xl-inline-block ms-2 me-1 ">
              <Link onClick={logout} className="logout">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              <span className="d-none d-xl-inline-block me-1">Logout</span>
              </Link>
          </span>  
        </div>
      </div>
    </header>
    {showRight.showRightSidebar ? <RightSidebar /> : null}
  </React.Fragment>
  );
};

export default Header;