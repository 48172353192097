import {combineReducers} from "redux";
import {login} from "./../Reducers/Login";
import {layout, showRight} from "./../Reducers/Layout";
import {bloglist,addblog,editblog,categorylist} from "./../Reducers/blogList"
import storage from 'redux-persist/lib/storage'
const appReducer = combineReducers({
  login,
  bloglist,
  editblog,
  addblog,
  layout,
  showRight,
  categorylist,
});
const rootReducer = (state, action) => {
  if(action.type === 'USER_LOGOUT'){
    storage.removeItem('persist:theme-root');
    state = {}
  }
  return appReducer(state, action)
}
export default rootReducer;