import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  Button,
  BreadcrumbItem,
  Badge,
} from "reactstrap";
import ReactQuill, { Quill } from "react-quill";
import "quill/dist/quill.snow.css";
// import ImageUploader from "quill-image-uploader";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../Constants/ActionTypes";
import { getApiCall, postApiCall } from "gfdu";
import GlobalVariables from "../../Constants/GlobalVariables";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Loader from "../../Route/Loader";
import axios from "axios";
// var Font = Quill.import("formats/font");
// Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];
// Quill.register(Font, true);

const AddBlog = () => {
  document.title = "Add Blog";
  const navigate = useNavigate();
  const [imageLoad, setImageLoad] = useState(false);
  const [bannerLink, setBannerLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const { addblog } = useSelector((state) => state);
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };

  useEffect(() => {
    getCategoryList();
    dispatch(
      updateState(ActionTypes.BLOG_ADD, {
        blog_list: [],
        title: "",
        add_blog_category_name: "",
        add_blog_permalink: "",
        add_blog_description: "",
        add_blog_category: null,
        tags: [],
        blog_image: "",
        add_blog_content: null,
      })
    );
  }, []);
  const quillRef = useRef();
  const handleChange = (e) => {
    dispatch(
      updateState(ActionTypes.BLOG_ADD, { [e.target.name]: e.target.value })
    );
  };

  const handleChangeCategory = (e) => {
    dispatch(
      updateState(ActionTypes.BLOG_ADD, {
        add_blog_category: e.value,
      })
    );
  };

  useEffect(() => {
    setCategoryList(
      addblog?.blog_categorylist?.map((detail, i) => {
        return { value: detail._id, label: detail.category_name };
      })
    );
  }, [addblog?.blog_categorylist]);
  const getCategoryList = () => {
    getApiCall(`v1/category/category-list`, (response) => {
      if (response.success) {
        dispatch(
          updateState(ActionTypes.BLOG_ADD, {
            blog_categorylist: response?.data,
          })
        );
      }
    });
  };
  const handleContentChange = (content, delta, source, editor) => {
    dispatch(updateState(ActionTypes.BLOG_ADD, { add_blog_content: content }));
  };
  const handleTagChange = (e) => {
    dispatch(updateState(ActionTypes.BLOG_ADD, { tags: e }));
  };

  const postAddBlog = () => {
    if (!addblog?.title) {
      toast.info("Title should not be empty");
    } else if (!addblog.add_blog_category) {
      toast.info("Category should not be empty");
    } else if (!addblog?.add_blog_permalink) {
      toast.info("permalink should not be empty");
    } else if (addblog?.tags.length < 1) {
      toast.info("Tags should not be empty");
    } else if (!addblog?.add_blog_description) {
      toast.info("Blog Description should not be empty");
    } else if (
      addblog?.add_blog_content.replace(/<(.|\n)*?>/g, "").trim().length === 0
    ) {
      toast.info("Blog Content should not be empty");
    } else if (!bannerLink) {
      toast.info("Image should not be empty");
    } else {
      const data = {
        blog_name: addblog?.title,
        blog_content: addblog?.add_blog_content,
        blog_category: addblog?.add_blog_category,
        permalink: addblog?.add_blog_permalink,
        blog_description: addblog?.add_blog_description,
        blog_banner: bannerLink,
        tag_name: addblog?.tags.toString(),
      };
      postApiCall(`v1/blog/create-blog`, data, (response) => {
        if (response.success) {
          navigate("/admin/blog");
          toast.success(response.message);
        } else{
          toast.error(response?.response?.data?.message);
        }
      });
    }
  };
  const uploadImage = async (event) => {
    const file = event.target.files[0];
    setLoading(false);
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/svg"
    ) {
      if (file.size > 5e6) {
        toast.info("Please upload a file smaller than 5 MB");
      } else {
        let data = new FormData();
        data.append("file", event.target.files[0]);
        data.append("upload_preset", "ifslewdl");
        data.append("api_key", "724539947133222");
        data.append("api_secret", "ByPeu1fryhajcdv_F7keQw8pyUs");
        axios
          .post("https://api.cloudinary.com/v1_1/foloosi/image/upload", data)
          .then((response) => {
            if (response) {
              setBannerLink(response?.data?.secure_url);
              setImageLoad(true);
              setLoading(true);
            }
          });
      }
    } else {
      toast.info(
        "File does not support. You must use .png, svg, .jpg and .jpeg"
      );
    }
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
    "size",
    // "font"
  ];
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          { 'header': [1, 2, 3, 4, 5, 6, false] },
          // { font: Font.whitelist },
            { 'size': ['small', false, 'large', 'huge'] },
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          "link",
          "image",
          "clean",
          "video",
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        handlers: {
          image: function (value) {
            const editor = quillRef.current.getEditor();
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();

            input.onchange = async () => {
              const file = input.files[0];
              setLoading(false);
              let data = new FormData();
              data.append("file", file);
              data.append("upload_preset", "ifslewdl");
              data.append("api_key", "724539947133222");
              data.append("api_secret", "ByPeu1fryhajcdv_F7keQw8pyUs");
              axios
                .post(
                  "https://api.cloudinary.com/v1_1/foloosi/image/upload",
                  data
                )
                .then((response) => {
                  if (response) {
                    toast.success(response.message);
                    const url = response?.data?.secure_url;
                    editor.insertEmbed(editor.getSelection(), "image", url);
                    setLoading(true);
                  } else {
                    toast.error("Upload failed");
                  }
                });
            };
          },

          video: function (value) {
            const editor = quillRef.current.getEditor();
            if (value) {
              var url = prompt("Enter Video URL");
              url = getVideoUrl(url);
              if (url) {
                editor.insertEmbed(editor.getSelection(), "video", url);
              }
            }
          },
          /* 
            audio: function(value) {
                const editor = quillRef.current.getEditor();
                if(value){
                    var url = window.prompt('Enter Audio URL:');
                    if (url) {
                        editor.insertEmbed(editor.getSelection(), 'audio', url);
                    }
                }
            } */
        },
      },
    }),
    []
  );

  const getVideoUrl = (url) => {
    let match =
      url?.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
      ) ||
      url?.match(
        /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
      ) ||
      url?.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
    if (match && match[2]?.length === 11) {
      return "https" + "://www.youtube.com/embed/" + match[2] + "?showinfo=0";
    }
    if (
      (match = url?.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))
    ) {
      return (
        (match[1] || "https") + "://player.vimeo.com/video/" + match[2] + "/"
      );
    }
    return null;
  };
  const deleteData = () => {
    setBannerLink("");
    setImageLoad(false);
  };
  return (
    <React.Fragment>
      {!loading && <Loader />}
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Add Blog</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>Blog</BreadcrumbItem>
                    <BreadcrumbItem active>Add Blog</BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div>
                    <Row className="mb-4">
                      <Label
                        htmlFor="blog-title"
                        className="col-sm-3 col-form-label"
                      >
                        Blog Title *
                      </Label>
                      <Col sm={4}>
                        <Input
                          type="text"
                          className="form-control"
                          id="blog-title"
                          placeholder="Enter Blog Title"
                          value={addblog?.title}
                          onChange={handleChange}
                          name="title"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4 antd-drop">
                      <Label
                        htmlFor=" blog-category"
                        className="col-sm-3 col-form-label"
                      >
                        Blog Category *
                      </Label>
                      <Col sm={4}>
                        <Select
                          className="select2-selection"
                          options={categoryList}
                          onChange={handleChangeCategory}
                          name="setCategoryList"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="blog-permalink"
                        className="col-sm-3 col-form-label"
                      >
                        Permalink *
                      </Label>
                      <Col sm={4}>
                        <Input
                          type="text"
                          className="form-control"
                          id="blog-permalink"
                          placeholder="Enter Blog Permalink"
                          value={addblog?.add_blog_permalink}
                          onChange={handleChange}
                          name="add_blog_permalink"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="tag-name"
                        className="col-sm-3 col-form-label"
                      >
                        Tag Name *
                      </Label>
                      <Col sm={7}>
                        <TagsInput
                          name="tags"
                          value={addblog?.tags}
                          onChange={handleTagChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="blog-description"
                        className="col-sm-3 col-form-label"
                      >
                        Blog Description *
                      </Label>
                      <Col sm={7}>
                        <Input
                          type="textarea"
                          className="form-control"
                          id="blog-description"
                          placeholder="Enter Blog Description"
                          name="add_blog_description"
                          value={addblog?.add_blog_description}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="blog-content"
                        className="col-sm-3 col-form-label"
                      >
                        Blog Content *
                      </Label>
                      <Col sm={7}>
                        <ReactQuill
                          ref={quillRef}
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          value={addblog?.add_blog_content}
                          onChange={handleContentChange}
                          name="add_blog_content"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="col-sm-3 col-form-label">
                        Upload File *
                      </Label>
                      <Col sm={7}>
                        {!imageLoad ? (
                          <label
                            htmlFor="imageUpload"
                            className="dropzone col-12 pointer"
                          >
                            <input
                              type="file"
                              className="hide"
                              id="imageUpload"
                              onChange={uploadImage}
                            />
                            <div className="dz-message needsclick mt-2">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                              </div>
                              <h4>Click to upload.</h4>
                            </div>
                          </label>
                        ) : (
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  src={bannerLink}
                                />
                              </Col>
                              <Col>
                                <span onClick={deleteData}>
                                  <i className="bx bx-trash bx-xs delete-icon pointer "></i>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col sm={9}>
                        <div>
                          <Button
                            color="primary"
                            className="w-md"
                            onClick={postAddBlog}
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddBlog;
