import React, { useEffect } from "react";
import { Routes,Route, Navigate } from 'react-router-dom';
import Home from "../blogsite/Home";
import View from "../blogsite/View";
import Error from "./Error";
import "../staticview/style/style.css";
const BlogRoutes = () => {
  return (
    <React.Fragment>  
          <Routes>  
          <Route path="/" element={<Home/>}/>
          <Route path="/details/:id" element={<View/>}/>
          <Route path="/*" element={<Error/>}/>
          </Routes>
       
    </React.Fragment>
  )
}

export default BlogRoutes;