
import React, { useEffect, useState } from "react";

function Pagination(props) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1); 
  },[props.searchFilter]);
  useEffect(() => {
    props.handle(page);
  },[page]);
  
  const next = () => {
    if (page < props.list) {
      setPage(page + 1);
    }
  };

  const previous = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <button
        className="btn btn-sm btn-info font-size-13"
        disabled={page === 1}
        onClick={previous}
      >
        &lt;&lt; Previous
      </button>

      <button
        className="btn btn-sm btn-success font-size-13 ms-1"
        disabled={page === props.list}
        onClick={next}
      >
        Next &gt;&gt;
      </button>
    </>
  );
}

export default Pagination;